




















// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// COMPONENTS
import vue2Dropzone from 'vue2-dropzone'

// CONFIGS
import { baseURL, getXSRFToken } from '@/utils/services/config'

@Component({
  components: {
    'vue-dropzone': vue2Dropzone,
  },
})
export default class Dropzone extends Vue {
  @Prop({ default: null })
  private info!: string

  @Prop({ default: null })
  private error!: string

  @Prop({ default: false })
  private isInvalid!: boolean

  private files: any[] = []
  private filesIds: number[] = []

  private get options () {
    return {
      // url: 'https://httpbin.org/post', // тестовый из либы
      url: `${baseURL}/upload-file`,
      acceptedFiles: 'image/*,application/pdf,application/xml,application/msword',
      addRemoveLinks: true,
      dictRemoveFile: '',
      dictCancelUpload: '',
      maxFilesize: 3,
      maxFiles: 10,
      previewTemplate: this.template(),
      autoProcessQueue: true,
      autoQueue: true,
      withCredentials: true,
      headers: this.headers,
    }
  }

  private get headers () {
    const headers: HeadersInit = {}

    const XSRFToken = getXSRFToken()

    if (XSRFToken) {
      headers['x-xsrf-token'] = `${XSRFToken}`
    }

    return headers
  }

  private template () {
    return `<div class="dropzone__preview dz-preview dz-file-preview">
        <div class="dz-info">
          <div class="left-half">
            <span class="dz-filetype file-type">FILE</span>
            <div class="dz-details">
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="dz-size"><span data-dz-size></span></div>
            </div>
          </div>
          <div class="right-half">
            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            <div class="dz-error-message error--text">Ошибка</div>
            <a class="dz-remove" href="javascript:undefined;" data-dz-remove>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z" fill="#D92B26"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    `
  }

  private load (file: any, response: any) {
    this.filesIds.push(response.id)
    this.files.push(file)
    file.previewElement.querySelector('.dz-progress').style.display = 'none'
    this.$emit('update', this.filesIds)
  }

  // private removeFile (file: any, _error: any, _xhr: any) {
  private removeFile (file: any) {
    const startIndex = this.files.indexOf((item: any) => item.name === file.name )
    this.files.splice(startIndex, 1)
    this.filesIds.splice(startIndex, 1)
    this.$emit('update', this.filesIds)
  }
}
